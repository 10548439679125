import React from "react";


const Header = ({ data }) => (
  <div className="about section" id="About">
    <div className="container">
   <div className="section-head">
    <h2>About us</h2>
     
    </div>
      <div className="about-main row">
      
       
        <div className=" col">
          <div className="about-details">
            
            <h3 className="sub-position">
              Our Professional{data.designation}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: data.description.childMarkdownRemark.html
              }}
            />
            <ul className="details">
              
               <li>
                <strong>Location</strong>
                <p>{data.location}</p>
              </li>
              
              <li>
                <strong>Email</strong>
                <p>
                  <a href={`mailto:${data.gmail}`}>{data.gmail}</a>
                </p>
              </li>
            </ul>
           
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Header;
