import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Banner from "../components/banner";
import About from "../components/about";
import Service from "../components/service";
import Work from "../components/work";
import Blogs from "../components/blogs";
import Testimonial from "../components/testimonial";
import Contact from "../components/contact";

const IndexPage = ({ data }) => (
  <Layout header="home">
    <SEO
      title={"SZ Accounting Services"}
      keywords={[`SZ Accounting Services`, `Accounting and Tax Services`, `Canada`]}
    />
    <Banner data={data.allContentfulAboutMe}></Banner>

    {data.contentfulSiteInformation.menus
      .filter(item => item === "About")
      .map(t => {
        return <About data={data.contentfulAboutMe}></About>;
      })}

    {data.contentfulSiteInformation.menus
      .filter(item => item === "Service")
      .map(t => {
        return <Service data={data.allContentfulService}></Service>;
      })}

    {data.contentfulSiteInformation.menus
      .filter(item => item === "Blogs")
      .map(t => {
        return <Blogs data={data.allContentfulBlogs}></Blogs>;
      })}

    {data.contentfulSiteInformation.menus
      .filter(item => item === "Work")
      .map(t => {
        return <Work data={data.allContentfulWhyWorkWithUs}></Work>;
      })}

    {data.contentfulSiteInformation.menus
      .filter(item => item === "Testimonials")
      .map(t => {
        return (
          <Testimonial data={data.allContentfulTestimonials}></Testimonial>
        );
      })}


    {data.contentfulSiteInformation.menus
      .filter(item => item === "Contact")
      .map(t => {
        return <Contact data={data.contentfulAboutMe.gmail}></Contact>;
      })}
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
query AboutQuery {
    allContentfulAboutMe (sort: {order: ASC, fields: createdAt}){
    nodes {
     name
    bannerImage {
      fluid(maxWidth: 1500) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
      }
    }
    bannerList
    }
  }
  contentfulAboutMe {
    name
    
    designation
    gmail
    id
    location
    description {
      childMarkdownRemark {
        html
      }
    }
    bannerImage {
      fluid(maxWidth: 1500) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
      }
    }
    bannerList
  }
  allContentfulService {
    edges {
      node {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
  allContentfulBlogs(limit: 5, sort: {fields: createdAt, order: DESC}) {
    edges {
      node {
        title
        slug
        featureImage {
          fluid(maxWidth: 600) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        createdAt
      }
    }
  }
  allContentfulTestimonials {
    edges {
      node {
        name
        avatarImage {
          fluid(maxWidth: 200) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
  allContentfulWorks {
    edges {
      node {
        siteName
        url
        image {
          fluid(maxWidth: 600) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
 
  allContentfulWhyWorkWithUs(sort: {order: ASC, fields: id}) {
    edges {
      node {
        workwithusDescription {
          workwithusDescription
        }
        workwithusTitle
      }
    }
  }

  contentfulSiteInformation {
    menus
  }
}
`;
