import React, { Component } from "react";
import Img from "gatsby-image";

export default class work extends Component {
  render() {  
    const { data } = this.props;

    return (
      <div className="work section" id="Work">
        <div className="container">
          <div className="section-head">
            <h2 className="text-center">Why Choose Us</h2>
            
          </div>

           <p class="text-center">We offer full range of accounting, tax and consultancy services to businesses and personal tax clients.</p>
          <ul className="work-list">
            {data.edges.map((item, index) => {
              return (
                <li key={index} className="item">
                  <div className="inner">
                   
                  

<div class="container">
	     

       <div class="why-us row">
               <div class="col">
                 <div class="box">
                  <span>0{index}</span>
                   <h4><a href="#">{item.node.workwithusTitle}</a></h4>
                  <p>{item.node.workwithusDescription.workwithusDescription}</p>
                 
                 </div>
               </div>
       
             
             </div>
             </div>
                  </div>
                </li>
              );
            })}
          </ul>


      </div>
      </div>
    );
  }
}
