import React, { Component } from "react";
import Slider from "react-slick"
import Img from "gatsby-image";

var settings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: true,
  autoplaySpeed: 4000,
  
};
export default class Banner extends Component {
  render() {
    
    const { data } = this.props;
    return (
      <div className="banner">
<Slider {...settings}>
            {data.nodes.map((item, index) => {
              return (
       <div key={index}>
   
        <Img
          fixed={item.bannerImage.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
        />
         <div className="container">
          <div className="banner-details">
            
            <h1 >{item.name}.</h1>
            
           <ul className="sub-data">
              {item.bannerList.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
            
          </div>
        </div> 
        </div>
          );
        })}
      </Slider>
      </div>
    );
  }
}
