import React, { Component } from "react";
import Slider from "react-slick";
import Img from "gatsby-image";
var settings = {
  dots: false,
  
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  autoplaySpeed: 1500,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export default class Testimonial extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="slider-section section testimonials" id="Testimonials">
        <div className="container">
          <div className="section-head text-left">
            <h2>Our Clients</h2>
            
          </div>
          <Slider {...settings}>
            {data.edges.map((item, index) => {
              return (
                <div key={index} className="testimonials-item">
                  <div className="testi-inner">
                    <Img
                      className="avatar"
                      fixed={item.node.avatarImage.fluid}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                    
                  </div>
                </div>

               

              );
            })}
          </Slider>
        </div>
      </div>
    );
  }
}
